import * as Sentry from '@sentry/sveltekit'
import { PUBLIC_APP_ENV } from '$env/static/public'

const traceRate = PUBLIC_APP_ENV === 'production' ? 0.1 : 0.5
Sentry.init({
  dsn: 'https://bdbbad3e03ec078be01db822915e5282@o4507460727930880.ingest.de.sentry.io/4507460731273296',
  maxBreadcrumbs: 30,
  tracesSampler: samplingContext => {
    if (samplingContext.attributes?.['sentry.origin'] === 'auto.function.sveltekit') {
      return 0
    }

    if (samplingContext.name.startsWith('/_app') || samplingContext.name.endsWith('/stats')) {
      return 0
    }

    return 0
  },
  beforeBreadcrumb: (breadcrumb) => {
    if (
      breadcrumb.data?.url.includes('/api/articles?') ||
      breadcrumb.data?.url.includes('google') ||
      breadcrumb.data?.url.includes('/_app') ||
      breadcrumb.data?.url.includes('__data.json')
    ) {
      return null
    }
    const unimportantLevels = ['log', 'warning', 'debug']
    if (breadcrumb.category === 'console' && unimportantLevels.includes(String(breadcrumb.level))) {
      return null
    }

    return breadcrumb
  },
  integrations: [Sentry.replayIntegration()],
  replaysSessionSampleRate: traceRate / 5,
  replaysOnErrorSampleRate: traceRate,
  environment: PUBLIC_APP_ENV,
  enabled: PUBLIC_APP_ENV !== 'local',
})

export const handleError = Sentry.handleErrorWithSentry()

