import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')
];

export const server_loads = [0,2,3,4];

export const dictionary = {
		"/[[lang=lang]]/(guest)": [~6,[2]],
		"/[[lang=lang]]/(guest)/apie": [7,[2]],
		"/[[lang=lang]]/(guest)/etikos-kodeksas": [8,[2]],
		"/[[lang=lang]]/(guest)/foto": [9,[2]],
		"/[[lang=lang]]/(user)/issaugotos-naujienos": [~22,[3]],
		"/[[lang=lang]]/(guest)/karjera": [10,[2]],
		"/[[lang=lang]]/(guest)/kontaktai": [11,[2]],
		"/[[lang=lang]]/logout": [~37],
		"/[[lang=lang]]/(guest)/naujiena/[id]": [~12,[2]],
		"/[[lang=lang]]/(user)/naujienos": [~23,[3]],
		"/[[lang=lang]]/(guest)/nekilnojamas-turtas": [13,[2]],
		"/[[lang=lang]]/(guest)/nuotraukos/[id]": [~14,[2]],
		"/[[lang=lang]]/(user)/nustatymai/naujas-srautas": [~24,[3]],
		"/[[lang=lang]]/(user)/nustatymai/naujas-srautas/[_id]": [~25,[3]],
		"/[[lang=lang]]/(guest)/pagrindine-naujiena": [~15,[2]],
		"/[[lang=lang]]/(user)/paieska": [~26,[3]],
		"/[[lang=lang]]/(visitor)/pamirsau-slaptazodi": [33,[5]],
		"/[[lang=lang]]/(guest)/prenumerata": [16,[2]],
		"/[[lang=lang]]/(visitor)/prisijungti": [~34,[5]],
		"/[[lang=lang]]/(guest)/privatumo-taisykles": [17,[2]],
		"/[[lang=lang]]/(user)/profilis": [27,[3,4]],
		"/[[lang=lang]]/(user)/profilis/keisti-slaptazodi": [~28,[3,4]],
		"/[[lang=lang]]/(user)/profilis/nustatymai": [29,[3,4]],
		"/[[lang=lang]]/(user)/profilis/pagalba": [30,[3,4]],
		"/[[lang=lang]]/(user)/profilis/paskyra": [31,[3,4]],
		"/[[lang=lang]]/(visitor)/registruotis": [35,[5]],
		"/[[lang=lang]]/(visitor)/slaptazodzio-keitimas": [~36,[5]],
		"/[[lang=lang]]/(guest)/spaudos-centras": [18,[2]],
		"/[[lang=lang]]/(guest)/spaudos-konferencijos": [19,[2]],
		"/[[lang=lang]]/(user)/spaudos-pranesimai": [~32,[3]],
		"/[[lang=lang]]/(guest)/sprintas": [20,[2]],
		"/[[lang=lang]]/(guest)/video": [21,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';